.loginHeader {
    min-height: 90px;
    background-color: #ff4d00;
    padding: 20px 80px;
}
.loginContainer {
    .loginFormCol {
        padding: 40px;
    }
}
.loginFooter {
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        text-align: center;
        font-size: 18px;
    }
}